import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Moment } from 'moment'

export class DateRange {
  constructor(public startDate: Moment | null, public endDate: Moment | null) {}
}

@Component({
  selector: 'localized-date-range-picker',
  template: `
    <div class="flex-1 flex flex-align-center--center flex-gap-10px">
      <span>
        {{ 'DATE_RANGE_PICKER.RANGE_FROM' | translate }}
      </span>
      <localized-date-picker
        [value]="dateRange.startDate"
        [disabled]="isDisabled"
        [label]="'DATE_RANGE_PICKER.RANGE_START_DATE' | translate"
        [max]="dateRange.endDate"
        (dateChange)="updateDate($event, true)"
        (clearClick)="updateDate(null, true)"
      ></localized-date-picker>
      <span>
        {{ 'DATE_RANGE_PICKER.RANGE_TO' | translate }}
      </span>
      <localized-date-picker
        [value]="dateRange.endDate"
        [disabled]="isDisabled"
        [label]="'DATE_RANGE_PICKER.RANGE_END_DATE' | translate"
        [min]="dateRange.startDate"
        (dateChange)="updateDate($event, false)"
        (clearClick)="updateDate(null, false)"
      ></localized-date-picker>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizedDateRangePickerComponent {
  @Input() isDisabled = false
  @Input()
  set value(dateRange: DateRange) {
    this.dateRange = dateRange
  }

  @Output() dateRangeChanged = new EventEmitter<DateRange>()

  dateRange: DateRange = new DateRange(null, null)

  updateDate($event: Moment, isStartDate: boolean): void {
    isStartDate
      ? (this.dateRange.startDate = $event)
      : (this.dateRange.endDate = $event)
    this.dateRangeChanged.emit(this.dateRange)
  }
}
