import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import isEqual from 'lodash.isequal'
import { DataTableSortUtils } from './jobsiteDataTableUtils/sort.utils'
import { DataTableColumnVisibility } from '../../../jobsites-management/jobsite-summary/jobsite-data-table/store/jobsite-datatable.selectors'
import { Metric } from '../../constants/metric.enum'
import { StatusType } from '../../../jobsites-management/jobsite-summary/jobsite-data-table/models/status.model'
import { TechniqueNames } from '../../remote-services/dtos/technique.dto'
import {
  ColumnChecksModel,
  WarningData,
} from '../../../jobsites-management/jobsite-summary/jobsite-data-table/models/column-checks.model'
import { Group } from '../../../jobsites-management/jobsite-summary/jobsite-data-table/models/group.model'
import { ColumnSelectDialogData } from '../../../jobsites-management/jobsite-summary/components/columns-select-dialog/columns-select-dialog.component'
import { TableFormatterService } from '../../../core/services/tableformatter.service'
import { LocalizedDateService } from '../../utils/services/localized-date.service'
import { TypedChanges } from '../../utils/TypedChange'
import { typedKeys } from '../../utils/typedKeys'
import { debounce } from 'lodash'
import { Subscription } from 'rxjs'
import { notEmpty } from '../../utils/notEmpty'
import { _isNumberValue } from '@angular/cdk/coercion'
import moment from 'moment/moment'
import { WarningChecksSettingsDialogComponent } from '../../../jobsites-management/settings/components/warning-checks-settings-dialog/warning-checks-settings-dialog.component'
import { JobsiteChecksSettingsDto } from '../../remote-services/dtos/jobsite-checks.model'
import { WarningChecksSettingsDialogResult } from '../../../jobsites-management/settings/models/warning-checks-settings.model'
import {
  SharedTableBase,
  SharedTableBaseDate,
  SortPreferenceState,
} from '../../models/shared-data-table.model'
import { DateRange } from '../../localized-date-time-picker/localized-date-range-picker.component'

@Component({
  selector: 'shared-data-table',
  templateUrl: './shared-data-table.component.html',
  styleUrls: ['./shared-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedDataTableComponent implements OnInit, OnChanges, OnDestroy {
  // Template inputs
  @Input() customColumnSlot: TemplateRef<any>
  @Input() columnSelectionColumnContent: TemplateRef<any>

  @Input() overwrittenColumns: Array<keyof SharedTableBase> = []
  @Input() jobsiteDataTableValues: SharedTableBase[] = []
  @Input() displayedTableColumns: Partial<
    DataTableColumnVisibility<SharedTableBase>
  >
  @Input() units: Record<string, string>
  @Input() jobsiteId: string
  @Input() selectedRowId: string
  @Input() selectedStatus: StatusType
  @Input() techniqueName: TechniqueNames
  @Input() readOnly: boolean
  @Input() maxLinksPerData: number
  @Input() columnsChecksMissingData: ColumnChecksModel[] = []
  @Input() columnsChecksWarning: ColumnChecksModel[] = []
  @Input() warningRowColoration: boolean
  @Input() enableGroupingForColumnDate = false // for now only the column 'date' can be grouped, this may be change later if needed
  @Input() localStorageKey = ''
  @Input() filteredColumns: Array<string>
  @Input() sortPreferenceEnabled: boolean
  @Input() sortByDateDescendingPref: boolean
  @Input() highlightColumnId: string
  @Input() checksSettings: JobsiteChecksSettingsDto
  @Input() showChecksSettingsButton: boolean

  @Output() selectedRowIdChange = new EventEmitter<string>()
  @Output() setDailyReportDataEvent = new EventEmitter<
    Array<Group | SharedTableBase>
  >()
  @Output() setSelectedStatusEvent = new EventEmitter<StatusType>()
  @Output() selectColumnsEvent = new EventEmitter<
    ColumnSelectDialogData<keyof SharedTableBase>
  >()
  @Output() openEmptyCoordinatesDialogEvent =
    new EventEmitter<SharedTableBase>()
  @Output() setGroupedEvent = new EventEmitter<boolean>()
  @Output() sortDateAndTimeChange = new EventEmitter<boolean>()
  @Output() sortByDateChangedManuallyChange = new EventEmitter<boolean>()
  @Output() setChecksSettings = new EventEmitter<JobsiteChecksSettingsDto>()
  @Output() resetChecksSettings = new EventEmitter<string>()

  tableColumnsToDisplay: Array<keyof SharedTableBase>
  allTableColumnsToDisplay: Array<keyof SharedTableBase | 'edit'>
  availableTableColumns: Array<keyof SharedTableBase>

  dataSource: MatTableDataSource<SharedTableBase | Group>

  grouped = false

  private subscriptions: Subscription[] = []
  private sortIsInitialized = false

  private debounceSearchValue = debounce(this.updateDataSourceFilter, 300, {})
  _searchValue = ''
  get searchValue(): string {
    return this._searchValue
  }
  @Input() set searchValue(value: string) {
    this._searchValue = value
    this.debounceSearchValue()
  }

  _sortDateAndTime = false
  get sortDateAndTime(): boolean {
    return this._sortDateAndTime
  }
  @Input() set sortDateAndTime(value: boolean) {
    this._sortDateAndTime = value
    if (this.sortIsInitialized) this.sort.sortChange.emit(this.sort)
  }

  _sortByDateChangedManually = false
  get sortByDateChangedManually(): boolean {
    return this._sortByDateChangedManually
  }
  @Input() set sortByDateChangedManually(value: boolean) {
    this._sortByDateChangedManually = value
    if (this.sortIsInitialized) this.sort.sortChange.emit(this.sort)
  }

  _searchOnAllFields = false
  get searchOnAllFields(): boolean {
    return this._searchOnAllFields
  }
  @Input() set searchOnAllFields(value: boolean) {
    this._searchOnAllFields = value
    this.updateDataSourceFilter()
  }

  @Input() set selectedDate(_value: DateRange) {
    this.updateDataSourceFilter()
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor(
    public dialog: MatDialog,
    private tableFormatterService: TableFormatterService,
    private localizedDateService: LocalizedDateService,
  ) {}

  ngOnInit(): void {
    this.initTableSort()
  }

  ngOnChanges(
    changes: TypedChanges<{
      jobsiteDataTableValues: SharedTableBase[]
      displayedTableColumns: DataTableColumnVisibility<SharedTableBase>
      units: Record<string, Metric>
      jobsiteId: string
      highlightColumnId: string
      selectedDateRange: DateRange
      selectedStatus: StatusType
      techniqueName: TechniqueNames
    }>,
  ): void {
    if (
      changes.displayedTableColumns &&
      !isEqual(
        changes.displayedTableColumns.currentValue,
        changes.displayedTableColumns.previousValue,
      )
    ) {
      this.setTableColumns()
    }
    if (
      changes.jobsiteDataTableValues &&
      !isEqual(
        changes.jobsiteDataTableValues.previousValue,
        changes.jobsiteDataTableValues.currentValue,
      )
    ) {
      if (
        !changes.jobsiteDataTableValues.previousValue ||
        (changes.jobsiteDataTableValues.previousValue?.length === 0 &&
          changes.jobsiteDataTableValues.currentValue?.length > 0)
      ) {
        this.setTableColumns()
        this.dataSource = new MatTableDataSource(this.jobsiteDataTableValues)
        this.updateDataSourceFilter()
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.subscriptions.push(
          this.dataSource
            .connect()
            .subscribe(_t =>
              this.setDailyReportDataEvent.emit(
                this.dataSource.sortData(
                  this.dataSource.filteredData.slice(),
                  this.dataSource.sort,
                ),
              ),
            ),
        )

        this.dataSource.paginator.firstPage()
        this.initTableSort()
      } else if (
        this.jobsiteDataTableValues?.filter(val => val != null).length > 0 &&
        (changes.jobsiteDataTableValues.currentValue?.length > 0 ||
          !isEqual(
            changes.selectedDateRange?.previousValue,
            changes.selectedDateRange?.currentValue,
          ))
      ) {
        this.dataSource.data = this.jobsiteDataTableValues
      } else if (changes.jobsiteDataTableValues.currentValue?.length === 0) {
        this.dataSource.data = []
        this.resetTableSort()
        this.resetTableColumns()
      }
    }

    if (this.dataSource && changes.highlightColumnId) {
      const selectedIndex = this.dataSource
        .sortData(this.dataSource.filteredData.slice(), this.sort)
        .filter((data: any): data is SharedTableBase => Boolean(data?.id))
        .map(data => data.id)
        .indexOf(this.selectedRowId)

      if (selectedIndex > -1) {
        this.dataSource.paginator.pageIndex = Math.floor(
          selectedIndex / this.paginator.pageSize,
        )
        // Force to repaint
        this.paginator._changePageSize(this.paginator.pageSize)
      }
    }
  }

  private setTableColumns(): void {
    const dataTableValues: SharedTableBase[] =
      this.jobsiteDataTableValues?.filter(notEmpty)
    if (dataTableValues && dataTableValues.length > 0) {
      this.availableTableColumns = typedKeys(dataTableValues[0])
        .filter(col => !this.filteredColumns.includes(col))
        .sort(
          (a, b) =>
            (this.displayedTableColumns[a]?.index ?? Number.MAX_SAFE_INTEGER) -
            (this.displayedTableColumns[b]?.index ?? Number.MAX_SAFE_INTEGER),
        )

      this.tableColumnsToDisplay = this.availableTableColumns.filter(
        col => this.displayedTableColumns[col]?.selected === true,
      )
      this.allTableColumnsToDisplay = this.availableTableColumns.filter(
        col => this.displayedTableColumns[col]?.selected === true,
      )
      this.allTableColumnsToDisplay.push('edit')
      this.allTableColumnsToDisplay.unshift('name')
      this.tableColumnsToDisplay.unshift('name')
      if (this.dataSource) {
        this.updateDataSourceFilter()
      }
    }
  }

  private resetTableColumns(): void {
    this.availableTableColumns = []
    this.tableColumnsToDisplay = []
    this.allTableColumnsToDisplay = []
  }

  getTableColumnValue(
    columnName: keyof SharedTableBase,
    element: Record<string, string | number>,
  ): string {
    if (element == null) return ''
    if (columnName === 'parentId' && element.parentId) {
      return (
        this.jobsiteDataTableValues?.find(v => v.id === element.parentId)
          ?.name ?? 'N/A'
      )
    }
    return this.tableFormatterService.getTableColumnValue(
      columnName,
      element[columnName],
    )
  }

  selectColumns(e: Event): void {
    e.stopPropagation()
    this.selectColumnsEvent.emit({
      columnsVisibility: this.displayedTableColumns,
      columnNames: this.availableTableColumns,
      units: this.units,
    })
  }

  selectRow(row: SharedTableBase): void {
    if (row.discarded) return
    this.selectedRowIdChange.emit(
      this.selectedRowId !== row.id ? row.id : undefined,
    )
    if (
      this.techniqueName === 'PILES' &&
      (row.localX == null || row.localY == null)
    ) {
      this.openEmptyCoordinatesDialogEvent.emit(row)
    }
  }

  //// for row grouping ////
  // based on https://stackblitz.com/edit/angular-material-table-row-grouping

  toggleGroupBy(event: Event): void {
    event.stopPropagation()

    if (this.dataSource.sort.active !== 'date') {
      this.resetTableSort()
    }

    if (this.grouped) {
      this.unGroupBy()
    } else {
      this.groupBy()
    }
  }

  private groupBy(): void {
    this.dataSource.data = this.addGroups(
      this.jobsiteDataTableValues as SharedTableBaseDate[],
    )
    this.grouped = true
    this.setGroupedEvent.emit(this.grouped)
    this.dataSource.filter = performance.now().toString()
  }

  private unGroupBy(): void {
    this.dataSource.data = this.jobsiteDataTableValues
    this.grouped = false
    this.setGroupedEvent.emit(this.grouped)
    this.initTableSort()
    this.applyFilter(this.searchValue)
  }

  private getDataRowVisible(data: SharedTableBaseDate): boolean {
    const groupRows = this.dataSource.data.filter(
      row => row instanceof Group && row.date === data.date,
    )

    if (groupRows.length === 0) {
      return true
    }
    const groupRow = groupRows[0] as Group
    return groupRow.expanded
  }

  groupHeaderClick(row: Group): void {
    const index = this.dataSource.data.indexOf(row)
    this.dataSource.data[index] = new Group(row, !row.expanded)
    this.dataSource.filter = performance.now().toString()
  }

  private addGroups(data: SharedTableBaseDate[]): (Group | SharedTableBase)[] {
    const groups = this.uniqueBy<Group>(
      data.map(row => {
        const result = new Group()
        result.date = row.date
        return result
      }),
      JSON.stringify,
    )

    let subGroups: (Group | SharedTableBase)[] = []
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group.date === row.date)
      group.totalCounts = rowsInGroup.length
      subGroups.push(group)
      subGroups = subGroups.concat(rowsInGroup)
    })
    return subGroups
  }

  private uniqueBy<T>(a: T[], hashFn: (t: T) => string): T[] {
    const seen: string[] = []
    return a.filter(item => {
      const hash = hashFn(item)
      if (seen.includes(hash)) {
        return false
      } else {
        seen.push(hash)
        return true
      }
    })
  }

  isGroup(index: number, item: unknown): item is Group {
    return item instanceof Group
  }

  ///////////////////

  onMatSortChange(sort: Sort): void {
    this.saveSortToLs({
      active: sort.active,
      direction: sort.direction,
    })
  }

  saveSortToLs(sort: Sort): void {
    if (!this.sortIsInitialized) return
    const sortPreference: SortPreferenceState = {
      ...sort,
      sortDateAndTime: this.sortDateAndTime,
      sortByDateChangedManually: this.sortByDateChangedManually,
    }
    localStorage.setItem(
      `${this.techniqueName}-${this.jobsiteId}-sort-preference-${this.localStorageKey}`,
      JSON.stringify(sortPreference),
    )
  }

  private initTableSort(): void {
    const sortPreference = this.getSortPreferenceFromLs()

    if (sortPreference?.sortByDateChangedManually != null) {
      this._sortByDateChangedManually =
        sortPreference?.sortByDateChangedManually
    } else {
      this._sortByDateChangedManually = sortPreference?.sortDateAndTime ?? false
    }
    this.sortByDateChangedManuallyChange.emit(this.sortByDateChangedManually)

    /** We use the default user preference from settings if the feature toggles
     * is enabled & user has not changed the sortDateAndTime toggle manually,
     * else we use the sortDateAndTime value from the local storage **/
    const sortDateTime =
      !this.sortByDateChangedManually && this.sortPreferenceEnabled
        ? this.sortByDateDescendingPref
        : sortPreference?.sortDateAndTime

    this._sortDateAndTime = sortDateTime ?? false
    this.sortDateAndTimeChange.emit(this.sortDateAndTime)

    this.dataSource.sortData = (d, s) => this.customSort(d, s)
    if (sortPreference !== null) {
      this.sort.active = sortPreference.active
      this.sort.direction = sortPreference.direction
      this.sort.sortChange.emit(this.sort)
    }
    this.sortIsInitialized = true
  }

  private resetTableSort(): void {
    this.dataSource.sort.active = null
    this.dataSource.sort.direction = ''
  }

  private getSortPreferenceFromLs(): SortPreferenceState {
    const sortPreferenceState = localStorage.getItem(
      `${this.techniqueName}-${this.jobsiteId}-sort-preference-${this.localStorageKey}`,
    )
    return JSON.parse(sortPreferenceState) as SortPreferenceState
  }

  private sortingDataAccessor(v: any, sortHeaderId: string): string | number {
    const value = (v as { [key: string]: any })[sortHeaderId]
    return _isNumberValue(value) ? Number(value) : value
  }

  private customSort(data: any[], sort: MatSort): any[] {
    if (this.sort.active === 'name') {
      return DataTableSortUtils.nameSort(data, sort.direction)
    }
    // angular default sort
    const active = sort.active
    const direction = sort.direction
    if (!active || direction === '') {
      return data
    }

    let sortedResult = data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active)
      let valueB = this.sortingDataAccessor(b, active)
      const valueAType = typeof valueA
      const valueBType = typeof valueB

      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += ''
        }
        if (valueBType === 'number') {
          valueB += ''
        }
      }
      let comparatorResult = 0
      if (valueA != null && valueB != null) {
        if (valueA > valueB) {
          comparatorResult = 1
        } else if (valueA < valueB) {
          comparatorResult = -1
        }
      } else if (valueA != null) {
        comparatorResult = 1
      } else if (valueB != null) {
        comparatorResult = -1
      }
      return comparatorResult * (direction === 'asc' ? 1 : -1)
    })
    // end default sort
    if (this.sortDateAndTime && this.sort.active !== 'time') {
      sortedResult = sortedResult.sort((a, b) => {
        if (a[sort.active] === b[sort.active]) {
          if (a.time == null && b.time == null) return 0
          if (a.time == null) return 1
          if (b.time == null) return -1
          return moment(a.time).isBefore(moment(b.time)) ? 1 : -1
        } else {
          return 0
        }
      })
    }
    return sortedResult
  }

  ///////////////////

  getDate(group: Group): string {
    return this.localizedDateService.getFormattedDate(group.date)
  }

  applyFilter(filterValue: string): void {
    if (this.dataSource == null) return
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  private setupDataSourceFilterPredicate(): void {
    if (this.dataSource == null) return
    this.dataSource.filterPredicate = (
      data: SharedTableBase | Group,
      filter: string,
    ): boolean => {
      if (this.enableGroupingForColumnDate && this.grouped) {
        return data instanceof Group
          ? true
          : this.getDataRowVisible(data as SharedTableBaseDate)
      }
      return data instanceof Group
        ? false
        : this.searchOnAllFields
        ? Object.keys(data)
            .filter(
              key => this.displayedTableColumns[key] != null || key === 'name',
            )
            .map(key => data[key])
            .join(' ')
            .toLowerCase()
            .includes(filter.toLowerCase())
        : data.name.toLowerCase().includes(filter.toLowerCase())
    }
  }

  updateDataSourceFilter(): void {
    this.setupDataSourceFilterPredicate()
    this.applyFilter(this.searchValue)
  }

  displayWarningIcon(
    column: keyof SharedTableBase,
    element: SharedTableBase,
  ): boolean {
    return (
      column === 'name' &&
      (this.getMissingDataColumn(element) != null ||
        this.getWarningColumn(element) != null)
    )
  }

  isRowInWarningColor(row: SharedTableBase): boolean {
    return (
      this.warningRowColoration &&
      this.columnsChecksWarning.find(value => value.id === row.id) != null &&
      this.selectedRowId !== row.id
    )
  }

  isRowInMissingDataColor(row: SharedTableBase): boolean {
    return (
      this.warningRowColoration &&
      this.columnsChecksMissingData.find(value => value.id === row.id) !=
        null &&
      this.selectedRowId !== row.id
    )
  }

  getWarningPopoverData(element: SharedTableBase): WarningData[] {
    return (
      this.getWarningColumn(element)?.warningData ??
      this.getMissingDataColumn(element)?.warningData
    )
  }

  private getWarningColumn(
    column: SharedTableBase,
  ): ColumnChecksModel | undefined {
    return this.columnsChecksWarning.find(value => value.id === column.id)
  }

  private getMissingDataColumn(
    column: SharedTableBase,
  ): ColumnChecksModel | undefined {
    return this.columnsChecksMissingData.find(value => value.id === column.id)
  }

  openWarningSettings(): void {
    const dialogRef = this.dialog.open<
      WarningChecksSettingsDialogComponent,
      JobsiteChecksSettingsDto,
      WarningChecksSettingsDialogResult
    >(WarningChecksSettingsDialogComponent, {
      data: this.checksSettings,
      restoreFocus: false,
      autoFocus: false,
      width: '700px',
    })
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.result) {
          result.isReset
            ? this.resetChecksSettings.emit(result.result.id)
            : this.setChecksSettings.emit(result.result)
        }
      }),
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}
