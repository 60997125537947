import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { JobsiteService } from 'app/shared/remote-services/jobsite.service'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { AsyncEffects } from 'app/shared/utils/redux/async.effects'
import { onCreate, onStart } from 'app/shared/utils/redux/operators'
import { forkJoin, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import Swal from 'sweetalert2'
import { getJobsiteId, getTechnique } from '../router/router.selectors'
import { State } from '../state'
import { JobsiteActions } from './jobsite.actions'
import { getJobsite } from './jobsite.selectors'
import { JobsiteAdminService } from '../../services/jobsite-admin.service'
import { IldHttpErrorResponse } from '../../../shared/utils/IldHttpError.type'

@Injectable()
export class JobsiteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private jobsiteService: JobsiteService,
    private jobsiteAdminService: JobsiteAdminService,
    private translate: TranslateService,
  ) {}

  loadJobsite = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteActions.loadJobsite),
      withLatestFrom(
        this.store.pipe(select(getTechnique), filter(notEmpty), take(1)),
      ),
      switchMap(([action, technique]) =>
        this.jobsiteService.getJobsite(action.payload, technique).pipe(
          map(jobsite =>
            JobsiteActions.loadJobsite.createComplete({
              techniqueId: technique,
              jobsite,
            }),
          ),
          catchError(_ => {
            return of(JobsiteActions.loadJobsite.createFailed())
          }),
        ),
      ),
    ),
  )

  updateJobsite$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteActions.updateJobsite),
      map(action => action.payload),
      withLatestFrom(
        this.store.pipe(select(getTechnique), filter(notEmpty), take(1)),
      ),
      switchMap(([_jobsite, technique]) =>
        this.jobsiteService.updateJobsite(_jobsite).pipe(
          map(jobsite =>
            JobsiteActions.updateJobsite.createComplete({
              techniqueId: technique,
              jobsite,
            }),
          ),

          tap(() =>
            Swal.fire({
              title: this.translate.instant('ALERT.UPDATED'),
              icon: 'success',
              confirmButtonText: 'Ok',
            }),
          ),
          catchError(() => of(JobsiteActions.updateJobsite.createFailed())),
        ),
      ),
    ),
  )

  setStatus$ = createEffect(() =>
    this.effectFactory.asyncEffect(JobsiteActions.setJobsiteStatus, action =>
      this.store.pipe(
        select(getTechnique),
        filter(notEmpty),
        take(1),
        withLatestFrom(this.store.pipe(select(getJobsiteId), filter(notEmpty))),
        switchMap(([technique, jobsiteId]) =>
          this.jobsiteService.setJobsiteStatus(technique, jobsiteId, action),
        ),
      ),
    ),
  )

  checkAvailability$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteActions.checkAvailability),
      debounceTime(500),
      map(a => a.payload),
      withLatestFrom(
        this.store.select(getTechnique),
        this.store.select(getJobsiteId),
      ),
      switchMap(([input, technique, jobsiteId]) =>
        forkJoin(
          of(input),
          this.jobsiteService.checkAvailability(input, technique, jobsiteId),
        ),
      ),
      mergeMap(([input, response]) => [
        JobsiteActions.setLastCheckValues.create(input),
        JobsiteActions.setCheckResponse.create(response),
      ]),
    ),
  )

  initCheckValues$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(JobsiteActions.initCheckValues),
      withLatestFrom(this.store.select(getJobsite)),
      mergeMap(([_, jobsite]) => [
        JobsiteActions.setLastCheckValues.create({
          name: jobsite.name,
          contractNumber: jobsite.contractNumber,
        }),
        JobsiteActions.setCheckResponse.create({
          nameAvailable: true,
          contractNumberAvailable: true,
        }),
      ]),
    ),
  )

  resetJobsite$ = createEffect(
    () => {
      return this.actions$.pipe(
        onCreate(JobsiteActions.resetJobsite),
        withLatestFrom(
          this.store.select(getTechnique),
          this.store.select(getJobsiteId),
        ),
        mergeMap(([a, technique, jobsiteId]) => {
          return forkJoin([
            of(a.payload),
            a.payload
              ? of({ isConfirmed: true })
              : Swal.fire({
                  title: this.translate.instant(
                    'JOBSITE_RESET.CONFIRMATION.TITLE',
                  ),
                  text: this.translate.instant(
                    'JOBSITE_RESET.CONFIRMATION.MESSAGE',
                  ),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonText: this.translate.instant('GENERAL.CANCEL'),
                  confirmButtonText: this.translate.instant(
                    'JOBSITE_RESET.CONFIRMATION.RESET',
                  ),
                }),
            of(technique),
            of(jobsiteId),
          ])
        }),
        switchMap(([force, result, technique, jobsiteId]) => {
          return of(force).pipe(
            switchMap(_ => {
              if (result.isConfirmed) {
                return this.jobsiteAdminService.resetJobsite(
                  technique,
                  jobsiteId,
                  force,
                )
              }
              return of()
            }),
            tap(_ =>
              Swal.fire({
                title: this.translate.instant('ALERT.UPDATED'),
                icon: 'success',
                confirmButtonText: 'Ok',
              }),
            ),
            catchError(async (err: IldHttpErrorResponse) => {
              const result = await Swal.fire({
                title: this.translate.instant('ALERT.ERROR'),
                text: this.translate.instant('EXCEPTIONS.' + err.error.message),
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonText: this.translate.instant('GENERAL.CANCEL'),
                confirmButtonText: this.translate.instant(
                  'JOBSITE_RESET.FORCE_RETRY',
                ),
              })
              if (result.isConfirmed) {
                this.store.dispatch(JobsiteActions.resetJobsite.create(true))
              }
            }),
          )
        }),
      )
    },
    { dispatch: false },
  )

  removeDuplicatedPileNames$ = createEffect(
    () => {
      return this.actions$.pipe(
        onCreate(JobsiteActions.removeDuplicatedPileNames),
        withLatestFrom(
          this.store.select(getTechnique),
          this.store.select(getJobsiteId),
        ),
        switchMap(([_, technique, jobsiteId]) =>
          this.jobsiteAdminService.removeDuplicatedPileNames(
            technique,
            jobsiteId,
          ),
        ),
      )
    },
    { dispatch: false },
  )
}
