/*
 * Generated type guards for "jobsite-favorite-kpi.dto.ts".
 * WARNING: Do not manually change this file.
 */
import {
  JobsiteFavoriteKpiType,
  FavoriteKpiDto,
  JobsiteFavoriteKpiDto,
  DisplayedFavoriteKPIDto,
  DisplayedFavoriteKpiPayload,
} from './jobsite-favorite-kpi.dto'
import { ProgressCategory } from '../../models/progress/progress-category.enum'
import { ReportFormat } from '../../models/export.model'
import { isStatsOverallRequest } from '../../models/stats.dto.type.guard'

export function isJobsiteFavoriteKpiType(
  obj: unknown,
): obj is JobsiteFavoriteKpiType {
  const typedObj = obj as JobsiteFavoriteKpiType
  return (
    typedObj === 'FIELDS' ||
    typedObj === 'AMOUNT_OF_DONE_PILES' ||
    typedObj === 'AMOUNT_OF_PARTIALLY_DONE_PILES' ||
    typedObj === 'AMOUNT_OF_LINEAR_METER' ||
    typedObj === 'AMOUNT_OF_VOLUME' ||
    typedObj === 'AMOUNT_OF_AREA_SIMPLE' ||
    typedObj === 'AMOUNT_OF_RECORDS' ||
    typedObj === 'AMOUNT_OF_WORKED_DAYS'
  )
}

export function isJobsiteFavoriteKpiDto(
  obj: unknown,
): obj is JobsiteFavoriteKpiDto {
  const typedObj = obj as JobsiteFavoriteKpiDto
  return (
    typedObj instanceof FavoriteKpiDto &&
    (typeof typedObj['jobsiteId'] === 'undefined' ||
      typeof typedObj['jobsiteId'] === 'string')
  )
}

export function isDisplayedFavoriteKPIDto(
  obj: unknown,
): obj is DisplayedFavoriteKPIDto {
  const typedObj = obj as DisplayedFavoriteKPIDto
  return (
    (isJobsiteFavoriteKpiDto(typedObj) as boolean) &&
    typeof typedObj['translateKey'] === 'string' &&
    (typeof typedObj['value'] === 'undefined' ||
      (((typedObj['value'] !== null && typeof typedObj['value'] === 'object') ||
        typeof typedObj['value'] === 'function') &&
        (typedObj['value']['value'] === null ||
          typeof typedObj['value']['value'] === 'number') &&
        (typedObj['value']['unit'] === null ||
          typeof typedObj['value']['unit'] === 'string')))
  )
}

export function isDisplayedFavoriteKpiPayload(
  obj: unknown,
): obj is DisplayedFavoriteKpiPayload {
  const typedObj = obj as DisplayedFavoriteKpiPayload
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    ((typedObj['jobsite'] !== null &&
      typeof typedObj['jobsite'] === 'object') ||
      typeof typedObj['jobsite'] === 'function') &&
    typeof typedObj['jobsite']['id'] === 'string' &&
    typeof typedObj['jobsite']['name'] === 'string' &&
    typeof typedObj['jobsite']['description'] === 'string' &&
    typeof typedObj['jobsite']['client'] === 'string' &&
    typeof typedObj['jobsite']['contractor'] === 'string' &&
    typeof typedObj['jobsite']['contractNumber'] === 'string' &&
    (typeof typedObj['jobsite']['businessUnitName'] === 'undefined' ||
      typeof typedObj['jobsite']['businessUnitName'] === 'string') &&
    (typeof typedObj['jobsite']['theoreticalStartDate'] === 'undefined' ||
      typedObj['jobsite']['theoreticalStartDate'] === null ||
      typeof typedObj['jobsite']['theoreticalStartDate'] === 'string' ||
      typeof typedObj['jobsite']['theoreticalStartDate'] === 'number' ||
      (typedObj['jobsite']['theoreticalStartDate'] instanceof Object &&
        typeof typedObj['jobsite']['theoreticalStartDate']['format'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['startOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['endOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['add'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['add'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['clone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['valueOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['local'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isLocal'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['utc'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isUTC'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isUtc'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['parseZone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isValid'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['invalidAt'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate'][
          'hasAlignedHourOffset'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['creationData'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['parsingFlags'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['year'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['year'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['years'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['years'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['quarter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['quarter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['month'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['month'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['months'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['months'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['day'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['day'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['days'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['days'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['date'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['date'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['dates'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['dates'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['hour'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['hour'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['hours'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['hours'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['minute'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['minute'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['minutes'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['minutes'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['second'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['second'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['seconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['seconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['millisecond'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['millisecond'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['milliseconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['milliseconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['week'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['week'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeek'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeek'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['weeksInYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isoWeeksInYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate'][
          'isoWeeksInISOWeekYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['dayOfYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['dayOfYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['from'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['to'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['fromNow'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toNow'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['diff'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toArray'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toDate'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toISOString'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['inspect'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toJSON'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['unix'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isLeapYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['zone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['zone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['utcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['utcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isUtcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['daysInMonth'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isDST'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['zoneAbbr'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['zoneName'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isBefore'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isAfter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isSame'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isSameOrAfter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isSameOrBefore'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isBetween'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['lang'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['lang'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['locale'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['locale'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['localeData'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['isDSTShifted'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['max'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['max'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['min'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['min'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['get'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['set'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['set'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalStartDate']['toObject'] ===
          'function') ||
      typedObj['jobsite']['theoreticalStartDate'] instanceof Date ||
      (Array.isArray(typedObj['jobsite']['theoreticalStartDate']) &&
        typedObj['jobsite']['theoreticalStartDate'].every(
          (e: any) => typeof e === 'string' || typeof e === 'number',
        )) ||
      (((typedObj['jobsite']['theoreticalStartDate'] !== null &&
        typeof typedObj['jobsite']['theoreticalStartDate'] === 'object') ||
        typeof typedObj['jobsite']['theoreticalStartDate'] === 'function') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['years'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['years'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['year'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['year'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['y'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['y'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['months'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['months'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['month'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['month'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['M'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['M'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['days'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['days'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['day'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['day'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['d'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['d'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['dates'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['dates'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['date'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['date'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['D'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['D'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['hours'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['hours'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['hour'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['hour'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['h'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['h'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['minutes'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['minutes'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['minute'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['minute'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['m'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['m'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['seconds'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['seconds'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['second'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['second'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['s'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['s'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['milliseconds'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['milliseconds'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['millisecond'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['millisecond'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalStartDate']['ms'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalStartDate']['ms'] ===
            'number'))) &&
    (typeof typedObj['jobsite']['realStartDate'] === 'undefined' ||
      typedObj['jobsite']['realStartDate'] === null ||
      typeof typedObj['jobsite']['realStartDate'] === 'string' ||
      typeof typedObj['jobsite']['realStartDate'] === 'number' ||
      (typedObj['jobsite']['realStartDate'] instanceof Object &&
        typeof typedObj['jobsite']['realStartDate']['format'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['startOf'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['endOf'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['add'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['add'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['clone'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['valueOf'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['local'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isLocal'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['utc'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isUTC'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isUtc'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['parseZone'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isValid'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['invalidAt'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['hasAlignedHourOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['creationData'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['parsingFlags'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['year'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['year'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['years'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['years'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['quarter'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['quarter'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['month'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['month'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['months'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['months'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['day'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['day'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['days'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['days'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['date'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['date'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['dates'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['dates'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['hour'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['hour'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['hours'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['hours'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['minute'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['minute'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['minutes'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['minutes'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['second'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['second'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['seconds'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['seconds'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['millisecond'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['millisecond'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['milliseconds'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['milliseconds'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['weekday'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['weekday'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeekday'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeekday'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['week'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['week'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['weeks'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['weeks'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeek'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeek'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['weeksInYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeeksInYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isoWeeksInISOWeekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['dayOfYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['dayOfYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['from'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['to'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['fromNow'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toNow'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['diff'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toArray'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toDate'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toISOString'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['inspect'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toJSON'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['unix'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isLeapYear'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['zone'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['zone'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['utcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['utcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isUtcOffset'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['daysInMonth'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isDST'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['zoneAbbr'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['zoneName'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isBefore'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isAfter'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isSame'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['isSameOrAfter'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isSameOrBefore'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isBetween'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['lang'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['lang'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['locale'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['locale'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['localeData'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['isDSTShifted'] ===
          'function' &&
        typeof typedObj['jobsite']['realStartDate']['max'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['max'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['min'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['min'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['get'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['set'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['set'] === 'function' &&
        typeof typedObj['jobsite']['realStartDate']['toObject'] ===
          'function') ||
      typedObj['jobsite']['realStartDate'] instanceof Date ||
      (Array.isArray(typedObj['jobsite']['realStartDate']) &&
        typedObj['jobsite']['realStartDate'].every(
          (e: any) => typeof e === 'string' || typeof e === 'number',
        )) ||
      (((typedObj['jobsite']['realStartDate'] !== null &&
        typeof typedObj['jobsite']['realStartDate'] === 'object') ||
        typeof typedObj['jobsite']['realStartDate'] === 'function') &&
        (typeof typedObj['jobsite']['realStartDate']['years'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['years'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['year'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['year'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['y'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['y'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['months'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['months'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['month'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['month'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['M'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['M'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['days'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['days'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['day'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['day'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['d'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['d'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['dates'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['dates'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['date'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['date'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['D'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['D'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['hours'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['hours'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['hour'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['hour'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['h'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['h'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['minutes'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['minutes'] ===
            'number') &&
        (typeof typedObj['jobsite']['realStartDate']['minute'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['minute'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['m'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['m'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['seconds'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['seconds'] ===
            'number') &&
        (typeof typedObj['jobsite']['realStartDate']['second'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['second'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['s'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['s'] === 'number') &&
        (typeof typedObj['jobsite']['realStartDate']['milliseconds'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['milliseconds'] ===
            'number') &&
        (typeof typedObj['jobsite']['realStartDate']['millisecond'] ===
          'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['millisecond'] ===
            'number') &&
        (typeof typedObj['jobsite']['realStartDate']['ms'] === 'undefined' ||
          typeof typedObj['jobsite']['realStartDate']['ms'] === 'number'))) &&
    ((typedObj['jobsite']['location'] !== null &&
      typeof typedObj['jobsite']['location'] === 'object') ||
      typeof typedObj['jobsite']['location'] === 'function') &&
    typeof typedObj['jobsite']['location']['latitude'] === 'number' &&
    typeof typedObj['jobsite']['location']['longitude'] === 'number' &&
    typeof typedObj['jobsite']['location']['label'] === 'string' &&
    Array.isArray(typedObj['jobsite']['techniques']) &&
    typedObj['jobsite']['techniques'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        (e['name'] === 'HF' || e['name'] === 'PILES') &&
        (typeof e['description'] === 'undefined' ||
          typeof e['description'] === 'string') &&
        (typeof e['status'] === 'undefined' ||
          e['status'] === 'NEW' ||
          e['status'] === 'DOING' ||
          e['status'] === 'DONE') &&
        (typeof e['progressCategory'] === 'undefined' ||
          e['progressCategory'] === ProgressCategory.COUNT ||
          e['progressCategory'] === ProgressCategory.LENGTH ||
          e['progressCategory'] === ProgressCategory.AREA_SIMPLE) &&
        typeof e['userFavorite'] === 'boolean' &&
        Array.isArray(e['statuses']) &&
        e['statuses'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') ||
              typeof e === 'function') &&
            (e['status'] === 'NEW' ||
              e['status'] === 'DOING' ||
              e['status'] === 'DONE') &&
            typeof e['date'] === 'string' &&
            typeof e['note'] === 'string',
        ),
    ) &&
    Array.isArray(typedObj['jobsite']['processes']) &&
    typedObj['jobsite']['processes'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['prettyName'] === null || typeof e['prettyName'] === 'string') &&
        (e['description'] === null || typeof e['description'] === 'string') &&
        typeof e['technique'] === 'string',
    ) &&
    typeof typedObj['jobsite']['units'] === 'string' &&
    (typedObj['jobsite']['status'] === 'NEW' ||
      typedObj['jobsite']['status'] === 'DOING' ||
      typedObj['jobsite']['status'] === 'DONE') &&
    Array.isArray(typedObj['jobsite']['companyIds']) &&
    typedObj['jobsite']['companyIds'].every(
      (e: any) => typeof e === 'string',
    ) &&
    Array.isArray(typedObj['jobsite']['regionIds']) &&
    typedObj['jobsite']['regionIds'].every((e: any) => typeof e === 'string') &&
    typeof typedObj['jobsite']['emailReferent'] === 'string' &&
    Array.isArray(typedObj['jobsite']['favoriteKPI']) &&
    typedObj['jobsite']['favoriteKPI'].every(
      (e: any) => isJobsiteFavoriteKpiDto(e) as boolean,
    ) &&
    typeof typedObj['jobsite']['autoSendDailyReport'] === 'boolean' &&
    Array.isArray(typedObj['jobsite']['dailyReportColumns']) &&
    typedObj['jobsite']['dailyReportColumns'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        (e['key'] === 'id' ||
          e['key'] === 'date' ||
          e['key'] === 'name' ||
          e['key'] === 'isDone' ||
          e['key'] === 'time' ||
          e['key'] === 'process' ||
          e['key'] === 'localX' ||
          e['key'] === 'localY' ||
          e['key'] === 'polygon' ||
          e['key'] === 'parentId' ||
          e['key'] === 'hfType' ||
          e['key'] === 'volume' ||
          e['key'] === 'volumeInst' ||
          e['key'] === 'startLiftDate' ||
          e['key'] === 'stopLiftDate' ||
          e['key'] === 'startLiftTime' ||
          e['key'] === 'stopLiftTime' ||
          e['key'] === 'startDrillDate' ||
          e['key'] === 'stopDrillDate' ||
          e['key'] === 'startDrillTime' ||
          e['key'] === 'stopDrillTime' ||
          e['key'] === 'drillHrs' ||
          e['key'] === 'drillHrsDec' ||
          e['key'] === 'liftHrs' ||
          e['key'] === 'liftHrsDec' ||
          e['key'] === 'plateformLevel' ||
          e['key'] === 'depthInst' ||
          e['key'] === 'depthProd' ||
          e['key'] === 'diameterInst' ||
          e['key'] === 'diameterProd' ||
          e['key'] === 'type' ||
          e['key'] === 'subtype' ||
          e['key'] === 'cage' ||
          e['key'] === 'nbr' ||
          e['key'] === 'rig' ||
          e['key'] === 'zone' ||
          e['key'] === 'timeSpentKS' ||
          e['key'] === 'depthDugKs' ||
          e['key'] === 'timeSpentBenne' ||
          e['key'] === 'depthDugBenne' ||
          e['key'] === 'links' ||
          e['key'] === 'overbreak' ||
          e['key'] === 'cageProd' ||
          e['key'] === 'concreteProd' ||
          e['key'] === 'discarded' ||
          e['key'] === 'pileVolume' ||
          e['key'] === 'asBuiltTheoVolume' ||
          e['key'] === 'prodEvents') &&
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['orderIndex'] === 'number' &&
        (e['sortDirection'] === null ||
          e['sortDirection'] === 'ASC' ||
          e['sortDirection'] === 'DESC'),
    ) &&
    typeof typedObj['jobsite']['daysAutoClosingLimit'] === 'number' &&
    (typedObj['jobsite']['dailyReportLang'] === null ||
      typedObj['jobsite']['dailyReportLang'] === 'en' ||
      typedObj['jobsite']['dailyReportLang'] === 'fr' ||
      typedObj['jobsite']['dailyReportLang'] === 'es' ||
      typedObj['jobsite']['dailyReportLang'] === 'hu') &&
    (typedObj['jobsite']['dailyReportUnitSystem'] === null ||
      typedObj['jobsite']['dailyReportUnitSystem'] === 'imperial' ||
      typedObj['jobsite']['dailyReportUnitSystem'] === 'metric') &&
    (typedObj['jobsite']['dailyReportFormat'] === ReportFormat.A4 ||
      typedObj['jobsite']['dailyReportFormat'] === ReportFormat.A3) &&
    typeof typedObj['jobsite']['dailyReportLandscape'] === 'boolean' &&
    typeof typedObj['jobsite']['dailyReportPrintMap'] === 'boolean' &&
    typeof typedObj['jobsite']['enableDailyManualProdRecord'] === 'boolean' &&
    (typedObj['jobsite']['soillibJobsiteId'] === null ||
      typeof typedObj['jobsite']['soillibJobsiteId'] === 'string') &&
    (typedObj['jobsite']['maxDepthDeviationReport'] === null ||
      typeof typedObj['jobsite']['maxDepthDeviationReport'] === 'number') &&
    (typedObj['jobsite']['maxDepthDeviationReportUnit'] === null ||
      typeof typedObj['jobsite']['maxDepthDeviationReportUnit'] === 'string') &&
    Array.isArray(typedObj['jobsite']['workingDays']) &&
    typedObj['jobsite']['workingDays'].every(
      (e: any) =>
        e === 'MONDAY' ||
        e === 'TUESDAY' ||
        e === 'WEDNESDAY' ||
        e === 'THURSDAY' ||
        e === 'FRIDAY' ||
        e === 'SATURDAY' ||
        e === 'SUNDAY',
    ) &&
    (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'] === 'undefined' ||
      typedObj['jobsite']['theoreticalEndOfTheDayTime'] === null ||
      typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'] === 'string' ||
      typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'] === 'number' ||
      (typedObj['jobsite']['theoreticalEndOfTheDayTime'] instanceof Object &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['format'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['startOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['endOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['add'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['add'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['subtract'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['calendar'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['clone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['valueOf'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['local'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isLocal'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['utc'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isUTC'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isUtc'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'parseZone'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isValid'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'invalidAt'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'hasAlignedHourOffset'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'creationData'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'parsingFlags'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['year'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['year'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['years'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['years'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['quarter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['quarter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['quarters'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['month'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['month'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['months'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['months'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['day'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['day'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['days'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['days'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['date'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['date'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['dates'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['dates'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hour'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hour'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hours'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hours'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minute'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minute'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minutes'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minutes'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['second'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['second'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['seconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['seconds'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'millisecond'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'millisecond'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'milliseconds'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'milliseconds'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weekday'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeekday'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeekday'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weekYear'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeekYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeekYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['week'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['week'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['weeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isoWeek'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isoWeek'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isoWeeks'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'weeksInYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeeksInYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isoWeeksInISOWeekYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'dayOfYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'dayOfYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['from'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['to'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['fromNow'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['toNow'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['diff'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['toArray'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['toDate'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'toISOString'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['inspect'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['toJSON'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['unix'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isLeapYear'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['zone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['zone'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'utcOffset'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'utcOffset'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isUtcOffset'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'daysInMonth'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isDST'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['zoneAbbr'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['zoneName'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isBefore'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isAfter'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['isSame'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isSameOrAfter'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isSameOrBefore'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isBetween'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['lang'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['lang'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['locale'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['locale'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'localeData'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'isDSTShifted'
        ] === 'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['max'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['max'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['min'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['min'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['get'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['set'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['set'] ===
          'function' &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['toObject'] ===
          'function') ||
      typedObj['jobsite']['theoreticalEndOfTheDayTime'] instanceof Date ||
      (Array.isArray(typedObj['jobsite']['theoreticalEndOfTheDayTime']) &&
        typedObj['jobsite']['theoreticalEndOfTheDayTime'].every(
          (e: any) => typeof e === 'string' || typeof e === 'number',
        )) ||
      (((typedObj['jobsite']['theoreticalEndOfTheDayTime'] !== null &&
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'] ===
          'object') ||
        typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'] ===
          'function') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['years'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['years'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['year'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['year'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['y'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['y'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['months'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['months'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['month'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['month'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['M'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['M'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['days'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['days'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['day'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['day'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['d'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['d'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['dates'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['dates'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['date'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['date'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['D'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['D'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hours'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hours'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hour'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['hour'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['h'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['h'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minutes'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
            'minutes'
          ] === 'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minute'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['minute'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['m'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['m'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['seconds'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
            'seconds'
          ] === 'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['second'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['second'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['s'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['s'] ===
            'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'milliseconds'
        ] === 'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
            'milliseconds'
          ] === 'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
          'millisecond'
        ] === 'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime'][
            'millisecond'
          ] === 'number') &&
        (typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['ms'] ===
          'undefined' ||
          typeof typedObj['jobsite']['theoreticalEndOfTheDayTime']['ms'] ===
            'number'))) &&
    Array.isArray(typedObj['fieldsKpi']) &&
    typedObj['fieldsKpi'].every(
      (e: any) => isStatsOverallRequest(e) as boolean,
    ) &&
    Array.isArray(typedObj['globalKpi']) &&
    typedObj['globalKpi'].every((e: any) => typeof e === 'string')
  )
}
