import { Injectable, OnDestroy } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl implements OnDestroy {
  unsubscribe: Subject<void> = new Subject<void>()

  constructor(private translate: TranslateService) {
    super()
    this.translate.onLangChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getItemLabels()
      })
    this.getItemLabels()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  getItemLabels(): void {
    this.translate
      .get([
        'PAGINATOR.ITEM_PER_PAGE',
        'PAGINATOR.NEXT_PAGE',
        'PAGINATOR.PREVIOUS_PAGE',
        'PAGINATOR.FIRST_PAGE',
        'PAGINATOR.LAST_PAGE',
      ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['PAGINATOR.ITEM_PER_PAGE']
        this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE']
        this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE']
        this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE']
        this.lastPageLabel = translation['PAGINATOR.LAST_PAGE']
        this.changes.next()
      })
  }

  getRangeLabel = (page, pageSize, length): string => {
    const ofsTr = this.translate.instant('PAGINATOR.OF')
    if (length === 0 || pageSize === 0) {
      return '0 ' + ofsTr + length
    }
    length = Math.max(length, 0)
    const startIndex = page * pageSize
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' ' + ofsTr + ' ' + length
  }
}
