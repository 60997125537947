import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { MatPaginatorIntlCro } from '../shared/pagination/mat-paginator-intl'
import { EffectsModule } from '@ngrx/effects'
import { effects, reducerInjecteur, reducerProvider } from './store'
import { StoreModule } from '@ngrx/store'
import { RouterStateSerializer } from '@ngrx/router-store'
import { guards } from './guards'
import { services } from './services'
import { SharedUtilsModule } from '../shared/utils/shared-utils.module'
import { TechniqueSpecificPipe } from '../shared/translation/technique-specific.pipe'
import { AsyncEffects } from '../shared/utils/redux/async.effects'
import { ChartDirectionFilterComponent } from '../shared/dashboards/chart-direction-filter/chart-direction-filter.component'
import { LeftRightSelectSettingsComponent } from '../shared/dashboards/settings/left-right-select-settings/left-right-select-settings.component'
import { CustomRouterStateSerializer } from './store/router/router-serializer'
import { ColumnErrorHandler } from '../shared/error-handling/ColumnErrorHandler'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { LocalizedDatePickerComponent } from '../shared/localized-date-time-picker/localized-date-picker.component'
import { MatBadgeModule } from '@angular/material/badge'
import { ToggleFavoriteComponent } from '../shared/forms/toggle-favorite/toggle-favorite.component'
import { ZlAutocompleteComponent } from 'app/shared/forms/zl-autocomplete/zl-autocomplete.component'
import { MatDatetimepickerModule } from '@mat-datetimepicker/core'
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment'
import { SplittedContainerComponent } from '../shared/components/splitted-container.component'
import { LocalizedDateRangePickerComponent } from '../shared/localized-date-time-picker/localized-date-range-picker.component'
import { sharedComponents } from '../shared/components'
import { sharedServices } from '../shared/service'
import { LocalizedDateRangeMonoPickerComponent } from '../shared/localized-date-time-picker/localized-date-range-mono-picker.component'
import { LocalizedDateTimePickerComponent } from '../shared/localized-date-time-picker/localized-date-time-picker.component'
import { TableHeaderTranslationPipe } from '../shared/utils/pipes/table-header-translation.pipe'
import { MdePopoverModule } from '@sde-ild/material-extended-mde'

export const exportCoreModules = [
  CommonModule,
  FormsModule,

  ReactiveFormsModule,
  TranslateModule,
  SharedUtilsModule,
  DragDropModule,
  MdePopoverModule,

  MatButtonToggleModule,
  MatBadgeModule,
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatNativeDateModule,
  MatSelectModule,
  MatInputModule,
  MatMomentDateModule,
  MatTabsModule,
  MatProgressBarModule,
  MatSliderModule,
  MatChipsModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatTreeModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatDividerModule,
  MatExpansionModule,
  MatListModule,
  MatRadioModule,

  MatDatepickerModule,
  MatMomentDatetimeModule,
  MatDatetimepickerModule,

  TableHeaderTranslationPipe,
]

@NgModule({
  imports: [
    ...exportCoreModules,
    StoreModule.forFeature('core', reducerInjecteur, {}),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    ...exportCoreModules,
    TechniqueSpecificPipe,
    ChartDirectionFilterComponent,
    LeftRightSelectSettingsComponent,
    LocalizedDatePickerComponent,
    LocalizedDateRangeMonoPickerComponent,
    LocalizedDateRangePickerComponent,
    LocalizedDateTimePickerComponent,
    ZlAutocompleteComponent,
    ToggleFavoriteComponent,
    SplittedContainerComponent,
    ...sharedComponents,
  ],

  declarations: [
    TechniqueSpecificPipe,
    ChartDirectionFilterComponent,
    SplittedContainerComponent,
    ...sharedComponents,
    LeftRightSelectSettingsComponent,
    LocalizedDatePickerComponent,
    LocalizedDateRangeMonoPickerComponent,
    LocalizedDateRangePickerComponent,
    LocalizedDateTimePickerComponent,
    ZlAutocompleteComponent,
    ToggleFavoriteComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStateSerializer,
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
        { provide: ErrorHandler, useClass: ColumnErrorHandler },
        ...guards,
        ...services,
        ...sharedServices,
        AsyncEffects,
        reducerProvider,
      ],
    }
  }
}
