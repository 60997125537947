<div class="boxShadow data-table-container flex-layout-column flex-fill">
  <div
    class="search-container flex-layout-row flex-50px flex-align-space-between--center"
  >
    <ng-content select="[search-item]"></ng-content>
    <div class="flex-layout-row flex-align-center--center">
      <button
        *ngIf="showChecksSettingsButton"
        class="warning-settings"
        mat-icon-button
        disableRipple
        matTooltip="{{ 'DATA_TABLE.WARNING_SETTINGS' | translate }}"
        [disabled]="readOnly"
        (click)="openWarningSettings()"
      >
        <mat-icon>settings</mat-icon>
      </button>
      <div
        class="flex-layout-row flex-align-center--center flex-gap-10px"
        [mdePopoverTriggerFor]="warningPopover"
        mdePopoverTriggerOn="hover"
        *ngIf="
          columnsChecksMissingData.length + columnsChecksWarning.length > 0
        "
      >
        <mat-icon color="primary">warning</mat-icon>
        <span>
          {{ columnsChecksMissingData.length + columnsChecksWarning.length }}
        </span>

        <mde-popover
          #warningPopover="mdePopover"
          [mdePopoverOverlapTrigger]="false"
          mdePopoverArrowColor="#d5d5d5"
        >
          <mat-card class="warning-popover">
            <mat-card-content>
              <h5
                translate
                [translateParams]="{
                  nb:
                    columnsChecksMissingData.length +
                    columnsChecksWarning.length
                }"
              >
                DATA_TABLE.WARNING_POPOVER.TITLE
              </h5>
              <ul>
                <li
                  translate
                  [translateParams]="{ nb: columnsChecksMissingData.length }"
                >
                  DATA_TABLE.WARNING_POPOVER.MISSING_DATA_COLUMNS
                </li>
                <li
                  translate
                  [translateParams]="{ nb: columnsChecksWarning.length }"
                >
                  DATA_TABLE.WARNING_POPOVER.WARNING_COLUMNS
                </li>
              </ul>
            </mat-card-content>
          </mat-card>
        </mde-popover>
      </div>
    </div>
    <mat-paginator
      class="flex-500px"
      [pageSizeOptions]="[10, 5, 20, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="onMatSortChange($event)"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of $any(tableColumnsToDisplay)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="
            enableGroupingForColumnDate && column === 'date' ? false : grouped
          "
        >
          <span>{{ column | translateHeader : units }}</span>
          <button
            *ngIf="enableGroupingForColumnDate && column === 'date'"
            (click)="toggleGroupBy($event)"
            class="grid-view-header-menu"
            mat-icon-button
          >
            <mat-icon>menu</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="customColumnSlot"
            [ngTemplateOutletContext]="{
              $implicit: column,
              element: element
            }"
          >
          </ng-container>
          <div
            *ngIf="!overwrittenColumns.includes(column)"
            class="flex-layout-row flex-align-center--center"
          >
            <button
              *ngIf="displayWarningIcon(column, element)"
              [mdePopoverTriggerFor]="rowWarningPopover"
              mdePopoverTriggerOn="hover"
              mat-icon-button
              disableRipple
            >
              <mat-icon class="warning-icon">warning</mat-icon>

              <mde-popover
                #rowWarningPopover="mdePopover"
                [mdePopoverOverlapTrigger]="false"
                mdePopoverArrowColor="#d5d5d5"
              >
                <mat-card class="warning-popover">
                  <mat-card-content>
                    <h5>
                      {{
                        (isRowInMissingDataColor(element)
                          ? 'DATA_TABLE.MISSING_DATA'
                          : 'DATA_TABLE.CHECKS_WARNING'
                        ) | translate
                      }}
                    </h5>
                    <ul *ngFor="let data of getWarningPopoverData(element)">
                      <li>
                        {{
                          'DATA_TABLE.' + data.translateKey
                            | translate : { value: data.value }
                        }}
                      </li>
                    </ul>
                    <div
                      *ngIf="isRowInMissingDataColor(element)"
                      class="flex-layout-row"
                    >
                      <mat-icon>info</mat-icon>
                      <span class="margin-auto">
                        {{ 'DATA_TABLE.CHECKS_NOT_DONE' | translate }}
                      </span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mde-popover>
            </button>
            <span>
              {{ getTableColumnValue(column, element) }}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef (click)="selectColumns($event)">
          <span
            class="edit-columns"
            matTooltip="{{ 'DATA_TABLE.SELECT_COLUMNS' | translate }}"
            >...</span
          >
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container
            [ngTemplateOutlet]="columnSelectionColumnContent"
            [ngTemplateOutletContext]="{
              $implicit: row
            }"
          >
          </ng-container>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="allTableColumnsToDisplay; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: allTableColumnsToDisplay"
        class="pile_row"
        [class.selected]="selectedRowId === row?.id"
        [class.warning]="isRowInWarningColor(row)"
        [class.missing-data]="isRowInMissingDataColor(row)"
        [class.row-discarded]="row?.discarded"
        (click)="selectRow(row)"
      ></tr>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <mat-cell colspan="999" *matCellDef="let group" style="cursor: pointer">
          <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
          <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
          <strong
            >{{ 'DATA_TABLE.date' | translate }} : {{ getDate(group) }} ({{
              group.totalCounts
            }})</strong
          >
        </mat-cell>
      </ng-container>

      <mat-row
        *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
        (click)="groupHeaderClick(row)"
      >
      </mat-row>
    </table>
    <div class="table-no-data-div" *ngIf="dataSource.data.length === 0">
      {{ 'DATA_TABLE.NO_DATA' | translate }}
    </div>
  </div>
</div>
