// Build information, automatically generated by `ng-info`
const build = {
    version: "20.65.0",
    timestamp: "Mon Sep 09 2024 15:17:57 GMT+0200 (Central European Summer Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "0724b6",
        fullHash: "0724b622a3d1f856cf0d04f321e34367b7f1fa4a"
    }
};

export default build;