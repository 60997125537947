<nav class="navbar navbar-default">
  <div class="flex-layout-row navbar-main-ctnr">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
        aria-expanded="false"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" [routerLink]="['/']">
        <img alt="zlyze" src="/assets/logos/zlyze.png" class="logo-menard" />
      </a>
      <div *ngIf="currentUrl !== '/'" class="navbar-brand">
        <span class="vertical-bar">&#x0007C;</span>
        <button
          mat-raised-button
          color="primary"
          type="button"
          class="jobsiteButton"
          (click)="back()"
        >
          {{ 'HEADER.BACK' | translate }}
        </button>
      </div>
      <div
        *ngIf="selectedJobsiteContractNumber"
        class="navbar-brand jobsite-name"
      >
        <span>{{ jobsiteName }}</span>
        <span class="jobsite-contract-number">{{
          selectedJobsiteContractNumber
        }}</span>
        <span *ngIf="jobsiteCompany" style="font-size: 15px"
          >- {{ jobsiteCompany.name }}</span
        >
        <span
          *ngIf="!jobsiteConfigurable"
          style="margin-left: 5px; font-size: 15px"
          >({{ 'JOBSITE_MANAGEMENT.LIST.READ_ONLY' | translate }})</span
        >
      </div>
    </div>

    <app-platform-disruptions
      *ngIf="message?.message && !closed"
      class="platform-disruption-popup"
      [message]="message.message"
      (closedChange)="updatePlatformDisruptionPopup($event)"
    >
    </app-platform-disruptions>

    <div
      class="flex-layout-row flex-align-end--center"
      style="padding-right: 10px"
    >
      <button mat-icon-button (click)="togglePlatformDisruptionPopup()">
        <mat-icon *ngIf="message?.message" color="primary">warning</mat-icon>
      </button>

      <a class="navbar-brand" [routerLink]="['/']">
        <img
          alt="Brand"
          *ngIf="headerLogoLink"
          [src]="headerLogoLink"
          class="logo-menard"
        />
      </a>
      <div>{{ username | titlecase }}</div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [disableRipple]="true"
        #menuTrigger="matMenuTrigger"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" class="flex-align-end">
        <button mat-menu-item [disabled]="true">
          {{ 'HEADER.PROFIL' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="languages">
          {{ 'HEADER.LANG' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="units">
          {{ 'HEADER.UNITS' | translate }}
        </button>
        <button mat-menu-item (click)="goToSettings()">
          {{ 'HEADER.SETTINGS' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="goToHelp()"
          [ngClass]="{ active: !hideOverlay }"
        >
          {{ 'HEADER.HELP' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          {{ 'HEADER.LOGOUT' | translate }}
        </button>
      </mat-menu>

      <mat-menu #languages="matMenu">
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'en' }"
          (click)="selectLanguage('en')"
        >
          English
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'fr' }"
          (click)="selectLanguage('fr')"
        >
          Français
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'es' }"
          (click)="selectLanguage('es')"
        >
          Español
        </button>
      </mat-menu>

      <mat-menu #units="matMenu">
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.unitSystem === 'metric' }"
          (click)="setUnitSystem('metric')"
        >
          {{ 'HEADER.MET' | translate }}
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.unitSystem === 'imperial' }"
          (click)="setUnitSystem('imperial')"
        >
          {{ 'HEADER.IMP' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-progress-bar
    style="z-index: 100"
    *ngIf="loading"
    mode="indeterminate"
  ></mat-progress-bar>
</nav>

<div class="overlay" *ngIf="!hideOverlay">
  <div class="arrow">
    <div class="text">{{ 'GENERAL.HERE_HELP_DOC' | translate }}</div>
    <mat-checkbox class="checkbox" (change)="onCheck($event.checked)">{{
      'GENERAL.NOT_REMIND' | translate
    }}</mat-checkbox
    ><br />
    <button mat-raised-button class="button" (click)="hide()">OK</button>
  </div>
</div>
