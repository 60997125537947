/*
 * Generated type guards for "export.model.ts".
 * WARNING: Do not manually change this file.
 */
import {
  ExportType,
  LaunchMultipleExportPayload,
  ExportDailyReportRKPayload,
  ExportDailyReportPayload,
  ReportFormat,
  ReportOrientation,
  DailyReportLanguage,
} from './export.model'
import { isHFDeviationExportParams } from '../components/export-pdf-dialog/export-pdf-dialog.model.type.guard'

export function isExportType(obj: unknown): obj is ExportType {
  const typedObj = obj as ExportType
  return (
    typedObj === 'CSV' ||
    typedObj === 'CUSTOM_PDF' ||
    typedObj === 'HF_DEVIATION' ||
    typedObj === 'HF_MACHINE' ||
    typedObj === 'HF_VERTICALITY' ||
    typedObj === 'RK_PILE_REPORT'
  )
}

export function isLaunchMultipleExportPayload(
  obj: unknown,
): obj is LaunchMultipleExportPayload {
  const typedObj = obj as LaunchMultipleExportPayload
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    Array.isArray(typedObj['columnIds']) &&
    typedObj['columnIds'].every((e: any) => typeof e === 'string') &&
    (typeof typedObj['step'] === 'undefined' ||
      typeof typedObj['step'] === 'number') &&
    (typeof typedObj['sma'] === 'undefined' ||
      typeof typedObj['sma'] === 'number') &&
    (typeof typedObj['deviation'] === 'undefined' ||
      (isHFDeviationExportParams(typedObj['deviation']) as boolean))
  )
}

export function isExportDailyReportRKPayload(
  obj: unknown,
): obj is ExportDailyReportRKPayload {
  const typedObj = obj as ExportDailyReportRKPayload
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['enableMap'] === 'boolean'
  )
}

export function isExportDailyReportPayload(
  obj: unknown,
): obj is ExportDailyReportPayload {
  const typedObj = obj as ExportDailyReportPayload
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isReportFormat(typedObj['format']) as boolean) &&
    typeof typedObj['landscape'] === 'boolean' &&
    typeof typedObj['printMap'] === 'boolean' &&
    typeof typedObj['globalComment'] === 'string' &&
    (typedObj['reportLanguage'] === null ||
      typedObj['reportLanguage'] === 'en' ||
      typedObj['reportLanguage'] === 'fr' ||
      typedObj['reportLanguage'] === 'es' ||
      typedObj['reportLanguage'] === 'hu')
  )
}

export function isReportFormat(obj: unknown): obj is ReportFormat {
  const typedObj = obj as ReportFormat
  return typedObj === ReportFormat.A4 || typedObj === ReportFormat.A3
}

export function isReportOrientation(obj: unknown): obj is ReportOrientation {
  const typedObj = obj as ReportOrientation
  return (
    typedObj === ReportOrientation.PORTRAIT ||
    typedObj === ReportOrientation.LANDSCAPE
  )
}

export function isDailyReportLanguage(
  obj: unknown,
): obj is DailyReportLanguage {
  const typedObj = obj as DailyReportLanguage
  return (
    typedObj === 'en' ||
    typedObj === 'fr' ||
    typedObj === 'es' ||
    typedObj === 'hu'
  )
}
