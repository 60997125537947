import { NgModule } from '@angular/core'
import { VarDirective } from './directives/ng-var.directive'
import { FileSizePipe } from './pipes/file-size.pipe'
import {
  LocalizedDatePipe,
  LocalizedDateTimePipe,
  LocalizedTimePipe,
  LocalizedValuePipe,
} from './pipes/localized-date.pipe'
import { HashDirective } from './directives/hash.directive'

@NgModule({
  imports: [],
  declarations: [
    LocalizedValuePipe,
    LocalizedDatePipe,
    LocalizedTimePipe,
    LocalizedDateTimePipe,
    FileSizePipe,
    HashDirective,
    VarDirective,
  ],
  exports: [
    LocalizedValuePipe,
    LocalizedDatePipe,
    LocalizedTimePipe,
    LocalizedDateTimePipe,
    FileSizePipe,
    HashDirective,
    VarDirective,
  ],
})
export class SharedUtilsModule {}
