import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular'
import { KeycloakService } from 'app/keycloak/keycloak.service'
import { onComplete, onCreate, onStart } from 'app/shared/utils/redux/operators'
import moment from 'moment'
import { defer, of } from 'rxjs'
import {
  concatMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { UserPreferencesService } from 'app/shared/remote-services/user-preferences.service'
import { AsyncEffects } from 'app/shared/utils/redux/async.effects'
import { State } from '../state'
import { UserActions } from './user.actions'
import { userPreferencesSelector } from './user.selectors'
import { DateTimeFormatService } from 'app/shared/service/date-time-format.service'
import { DateTimeFormatsSettingsActions } from 'app/jobsites-management/settings/store/date-time-format/date-time-formats-settings.actions'
import { SettingsActions } from '../../../jobsites-management/settings/store/settings.actions'
import { UUID } from '../../../shared/models/utils.type'
import {
  APP_LANGUAGES,
  HomePagePreferenceDto,
  UserPreferencesDto,
} from '../../../shared/remote-services/dtos/userPreferences.dto'
import { TypedAction } from '../../../shared/utils/redux/loadable/loadable.actions'
import { DAILY_REPORT_LANGUAGES } from '../../../jobsites-management/jobsite-summary/models/export.model'

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private keycloakService: KeycloakService,
    private userPreferencesService: UserPreferencesService,
    private translateService: TranslateService,
    private formatService: DateTimeFormatService,
  ) {}

  loadUser$ = createEffect(() =>
    this.effectFactory.asyncEffect(UserActions.loadUser, () =>
      of(this.keycloakService.getUser()).pipe(
        tap(user =>
          Sentry.configureScope(scope => {
            scope.setUser(user)
          }),
        ),
      ),
    ),
  )

  loadUserPreferences$ = createEffect(() =>
    this.effectFactory.asyncEffect(UserActions.loadUserPreferences, () =>
      this.userPreferencesService.getUserSettings(),
    ),
  )

  userPreferencesLoaded$ = createEffect(() =>
    this.actions$.pipe(
      onComplete(UserActions.loadUserPreferences),
      map(action => action.payload),
      concatMap(userPref => {
        const actions: TypedAction<unknown>[] = [
          DateTimeFormatsSettingsActions.setDateTimeFormatsSettings.create(
            this.formatService.parseBackendFormatsSettings(
              userPref.zlDateTimeFormats,
            ),
          ),
        ]

        if (Object.values(userPref.homePage).some(pref => pref.id != null)) {
          actions.push(SettingsActions.isDefaultHomePagePref.create(false))
        }

        return actions
      }),
    ),
  )

  updateLanguage$ = createEffect(() =>
    this.actions$.pipe(
      onStart(UserActions.updateLanguage),
      switchMap(action =>
        this.userPreferencesService
          .updateLanguagePreference(action.payload.language)
          .pipe(
            map(() =>
              UserActions.loadUserPreferences.createComplete(action.payload),
            ),
          ),
      ),
    ),
  )

  updateTutoIsRead$ = createEffect(() =>
    this.actions$.pipe(
      onStart(UserActions.updateTutoIsRead),
      switchMap(action =>
        this.userPreferencesService
          .updateTutoIsRead(action.payload.tutoIsRead)
          .pipe(
            map(() =>
              UserActions.loadUserPreferences.createComplete(action.payload),
            ),
          ),
      ),
    ),
  )

  updateUnitSystem$ = createEffect(
    () =>
      this.actions$.pipe(
        onCreate(UserActions.updateUnitSystem),
        switchMap(action =>
          this.userPreferencesService
            .updateUnitSystemPreference(action.payload.unitSystem)
            .pipe(map(() => window.location.reload())),
        ),
      ),
    { dispatch: false },
  )

  init$ = createEffect(
    () =>
      defer(() => of(null)).pipe(
        tap(() => {
          this.translateService.addLangs([...DAILY_REPORT_LANGUAGES])
          this.translateService.setDefaultLang('en')
        }),
      ),
    { dispatch: false },
  )

  setLanguage$ = createEffect(
    () =>
      this.store.pipe(
        select(userPreferencesSelector.getValue),
        filter(userPref => !!userPref),
        map(userPref => userPref.language),
        tap(lang => {
          if (APP_LANGUAGES.includes(lang)) {
            this.translateService.use(lang)
            moment.locale(lang)
          } else {
            const browserLang = this.translateService.getBrowserLang()
            this.translateService.use(
              browserLang.match(/en|fr|es/) ? browserLang : 'en',
            )
          }
        }),
      ),
    { dispatch: false },
  )

  updateDateTimeFormats$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(UserActions.updateDateTimeFormats),
      withLatestFrom(this.store.select(userPreferencesSelector.getValue)),
      map(
        ([action, userPreferences]) =>
          ({
            ...userPreferences,
            zlDateTimeFormats: action.payload,
          } as UserPreferencesDto),
      ),
      switchMap(userPreferences =>
        this.userPreferencesService
          .updateDateTimeFormatsPreferences(userPreferences.zlDateTimeFormats)
          .pipe(
            concatMap(() => [
              SettingsActions.toggleDisplaySaveAlert.create(true),
              UserActions.loadUserPreferences.createComplete(userPreferences),
            ]),
          ),
      ),
    ),
  )

  updateRigPathEnabled$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(UserActions.updateRigPathEnabled),
      withLatestFrom(this.store.select(userPreferencesSelector.getValue)),
      map(
        ([action, userPreferences]) =>
          ({
            ...userPreferences,
            zlRigPathEnabled: action.payload,
          } as UserPreferencesDto),
      ),
      switchMap(userPreferences =>
        this.userPreferencesService
          .updateRigPathEnabledPreference(userPreferences.zlRigPathEnabled)
          .pipe(
            map(() =>
              UserActions.loadUserPreferences.createComplete(userPreferences),
            ),
          ),
      ),
    ),
  )

  updateHomePagePreferences$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(UserActions.updateHomePagePreferences),
      map(action => {
        const result: Record<UUID, HomePagePreferenceDto> = {}
        action.payload.forEach((val: HomePagePreferenceDto, key: UUID) => {
          result[key] = val
        })
        return result
      }),
      withLatestFrom(this.store.select(userPreferencesSelector.getValue)),
      map(
        ([homePage, userPreferences]) =>
          ({
            ...userPreferences,
            homePage,
          } as UserPreferencesDto),
      ),
      switchMap(preferences =>
        this.userPreferencesService
          .updateHomePagePreferences(preferences.homePage)
          .pipe(
            concatMap(() => [
              SettingsActions.toggleDisplaySaveAlert.create(true),
              SettingsActions.isDefaultHomePagePref.create(false),
              UserActions.loadUserPreferences.createComplete(preferences),
            ]),
          ),
      ),
    ),
  )

  updateAsBuiltSortDateDescending$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(UserActions.updateAsBuiltSortDateDescending),
      withLatestFrom(this.store.select(userPreferencesSelector.getValue)),
      map(
        ([action, userPreferences]) =>
          ({
            ...userPreferences,
            zlAsBuiltDescendingDate: action.payload,
          } as UserPreferencesDto),
      ),
      switchMap(preferences =>
        this.userPreferencesService
          .updateAsBuiltSortDateDescending(preferences.zlAsBuiltDescendingDate)
          .pipe(
            map(() =>
              UserActions.loadUserPreferences.createComplete(preferences),
            ),
          ),
      ),
    ),
  )
}
