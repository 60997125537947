import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { UploadFileModel } from '../../models/upload-file.model'

// FIXME: Does not seem to be used, maybe to delete
@Component({
  selector: 'uploader-files',
  template: `
    <mat-expansion-panel
      *ngIf="isVisible"
      [expanded]="isExpanded"
      [hideToggle]="true"
    >
      <mat-expansion-panel-header>
        <div class="flex flex-1 flex-align--center flex-gap-10px">
          <span
            *ngIf="nbWaitingFiles"
            translate
            [translateParams]="{ nb: nbWaitingFiles }"
            >WIDGET.UPLOAD.TITLE_UPLOADING</span
          >
          <span
            *ngIf="!nbWaitingFiles"
            translate
            [translateParams]="{ nb: files.length }"
            >WIDGET.UPLOAD.TITLE_UPLOADED</span
          >
          <mat-progress-spinner
            *ngIf="nbWaitingFiles"
            [diameter]="20"
            color="primary"
            mode="indeterminate"
          >
          </mat-progress-spinner>
          <div class="flex-1"></div>
          <mat-icon aria-label="Close" (click)="close()">close</mat-icon>
        </div>
      </mat-expansion-panel-header>

      <mat-list>
        <div *ngFor="let file of files; trackBy: trackByFile; let last = last">
          <mat-list-item>
            <div class="flex flex-fill flex-align--center">
              <div class="flex-layout-column flex-75">
                <div class="fileName" [title]="file.file.name">
                  {{ file.file.name }}
                </div>
                <div class="progressbar flex-25">
                  <div
                    class="inner-progressbar"
                    [style.width]="file.progress + '%'"
                  ></div>
                </div>
              </div>
              <div class="flex-1"></div>
              <div class="flex-60px flex flex-align-end--center">
                <mat-icon class="green-icon" *ngIf="file.loaded">
                  check_circle
                </mat-icon>
                <mat-icon
                  color="primary"
                  class="clickable"
                  aria-label="Error"
                  [matTooltip]="file.errorDesc"
                  *ngIf="file.inError"
                  (click)="retryFile(file)"
                >
                  error
                </mat-icon>
                <mat-icon
                  color="accent"
                  class="clickable"
                  aria-label="cancel"
                  (click)="cancelFile(file)"
                >
                  cancel
                </mat-icon>
              </div>
            </div>
          </mat-list-item>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </mat-list>
    </mat-expansion-panel>
  `,
  styleUrls: ['./uploader-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploaderFilesComponent {
  @Input() files: UploadFileModel[] = []
  @Input() isExpanded: boolean
  @Input() isVisible: boolean
  @Input() nbWaitingFiles: number

  @Output() setIsExpandedEvent = new EventEmitter<boolean>()
  @Output() closeEvent = new EventEmitter<void>()
  @Output() cancelFileEvent = new EventEmitter<string>()
  @Output() retryFileEvent = new EventEmitter<UploadFileModel>()

  close(): void {
    this.closeEvent.emit()
  }

  cancelFile(file: UploadFileModel): void {
    this.cancelFileEvent.emit(file.id)
  }

  retryFile(file: UploadFileModel): void {
    this.retryFileEvent.emit(file)
  }

  trackByFile(index: number, file: UploadFileModel): string {
    return file.id
  }

  public isUploading(): boolean {
    return this.files.some(file => !file.loaded && !file.inError)
  }
}
