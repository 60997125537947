import { createSelector } from '@ngrx/store'
import { State } from 'app/core/store'
import {
  getJobsiteId,
  getTechnique,
} from 'app/core/store/router/router.selectors'
import { getLanguage, getUnitSystem } from 'app/core/store/user'
import { DailyReportDto } from 'app/shared/remote-services/dtos/dailyReport.dto'
import { UnitSystem } from 'app/shared/remote-services/dtos/userPreferences.dto'
import { exhaustiveCheck } from 'app/shared/utils/exhautisveCheck'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import moment from 'moment'
import { ColumnDescriptor } from '../../../../shared/utils/column-descriptor'
import { migrateColumnDescriptorIfNeeded } from '../../../../shared/utils/localstorage-schema-migrations'
import {
  cageProdRecordSelectors,
  columnProdSelectors,
  concreteProdRecordSelectors,
  doneColumnIdsSelectors,
  getColumns,
  getProdEventSummaries,
} from '../../store/jobsite-summary.selectors'
import { ColumnChecksModel, WarningData } from '../models/column-checks.model'
import { getState } from './state'
import { TechniqueNames } from '../../../../shared/remote-services/dtos/technique.dto'
import { LoadableSelectorFactory } from '../../../../shared/utils/redux/loadable/loadable.selectors'
import { JobsiteChecksSettingsDto } from '../../../../shared/remote-services/dtos/jobsite-checks.model'
import { JobsiteTableDataValues } from '../models/jobsite-table-data-values.model'

const getDisplayedTableColumns = new EntitiesSelectorFactory(
  getState,
  state => state.displayedTableColumns,
)

export const getSelectedDateRange = createSelector(
  getState,
  state => state.selectedDateRange,
)

export const getSelectedStatus = createSelector(
  getState,
  state => state.SelectedStatus,
)

export type DataTableColumnVisibility<T> = {
  [columnName in keyof T]: ColumnDescriptor
}

export const getCurrentDisplayedTableColumns = createSelector<
  State,
  [
    {
      [jobsiteId: string]: {
        id: string
        tableColumns: DataTableColumnVisibility<JobsiteTableDataValues>
      }
    },
    string,
  ],
  Partial<DataTableColumnVisibility<JobsiteTableDataValues>>
>(
  getDisplayedTableColumns.getEntities,
  getJobsiteId,
  (tcEntities, jobsiteId) => {
    const result =
      tcEntities?.[jobsiteId]?.tableColumns ?? getDefaultDisplayedTableColumns
    return migrateColumnDescriptorIfNeeded(result as Record<string, any>)
  },
)

export const getDefaultDisplayedTableColumns: Partial<
  DataTableColumnVisibility<JobsiteTableDataValues>
> = {
  name: new ColumnDescriptor(0, true),
  date: new ColumnDescriptor(1, true),
  time: new ColumnDescriptor(2, true),
  isDone: new ColumnDescriptor(3, true),
  process: new ColumnDescriptor(4, true),
  localX: new ColumnDescriptor(5, true),
  localY: new ColumnDescriptor(6, true),
  volume: new ColumnDescriptor(7, true),
  volumeInst: new ColumnDescriptor(8, true),
  startLiftDate: new ColumnDescriptor(9, true),
  stopLiftDate: new ColumnDescriptor(10, true),
  plateformLevel: new ColumnDescriptor(11, true),
  depthInst: new ColumnDescriptor(12, true),
  depthProd: new ColumnDescriptor(13, true),
  diameterInst: new ColumnDescriptor(14, true),
  diameterProd: new ColumnDescriptor(15, true),
  type: new ColumnDescriptor(16, true),
  subtype: new ColumnDescriptor(17, true),
  cage: new ColumnDescriptor(18, true),
  nbr: new ColumnDescriptor(19, true),
  rig: new ColumnDescriptor(20, true),
  zone: new ColumnDescriptor(21, true),
  links: new ColumnDescriptor(22, true),
  discarded: new ColumnDescriptor(23, true),
}

export const getDailyReportData = createSelector(
  getState,
  state => state?.dailyReportData,
)

function getDuration(
  columnProd: DailyReportDto,
  param: (columnProd: DailyReportDto) => number,
) {
  if (!columnProd) {
    return null
  }

  const durationInMinutes = param(columnProd)
  if (durationInMinutes === undefined) {
    return null
  }

  return `${Math.floor(durationInMinutes / 60)}:${Number(
    durationInMinutes % 60,
  ).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`
}

function getDurationDec(columnProd, param: (columnProd) => number, language) {
  if (!columnProd) {
    return null
  }

  const durationInMinutes = param(columnProd)
  if (durationInMinutes === undefined) {
    return null
  }

  return `${(durationInMinutes / 60).toLocaleString(language, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`
}

function getTheoryVolume(
  diameter: number,
  depth: number,
  unitSystem: UnitSystem,
): number | null {
  if (diameter && depth) {
    if (unitSystem === 'metric') {
      // diameter mm to m
      return Math.PI * Math.pow(diameter / 2000, 2) * depth
    } else if (unitSystem === 'imperial') {
      // diameter inch to ft
      return Math.PI * Math.pow((0.0833 * diameter) / 2, 2) * depth
    }
    exhaustiveCheck(unitSystem)
  }
  return null
}

function getOverbreak(
  process,
  dailyReport: DailyReportDto,
  theoryVolume: number,
): number {
  if (process !== 'DMX') {
    return dailyReport?.overbreak
  } else {
    if (theoryVolume && dailyReport?.volume) {
      return parseFloat(
        ((dailyReport?.volume / theoryVolume) * 100 - 100).toFixed(2),
      )
    }
    return null
  }
}

// createSelector has a limited function arity in type def
// waiting for ngrx to use variadic tuple type, in the meantine splitting in subSelector
const getSubJobsiteDataTableValues = createSelector(
  getState,
  getProdEventSummaries,
  cageProdRecordSelectors.getEntities,
  concreteProdRecordSelectors.getEntities,
  (state, ...a) => [...a] as const,
)

export const getChecksSettings = new LoadableSelectorFactory(
  getState,
  state => state.checksSettings,
)

export const getJobsiteDataTableValues = createSelector(
  getColumns.getAll,
  columnProdSelectors.getValue,
  doneColumnIdsSelectors.getAll,
  getChecksSettings.getValue,
  getLanguage,
  getUnitSystem,
  getSelectedDateRange,
  getSelectedStatus,
  getSubJobsiteDataTableValues,
  (
    columns,
    columnsProd,
    columnsDone,
    checksSettings,
    language,
    unitSystem,
    selectedDateRange,
    selectedStatus,
    [prodEventSummaries, cageProdRecords, concreteProdRecords],
  ): JobsiteTableDataValues[] => {
    if (columnsProd) {
      return columns
        .map((column): JobsiteTableDataValues => {
          const dailyReport: DailyReportDto = columnsProd[column.key.id]
          const theoryVolume = getTheoryVolume(
            column.values.diameter,
            column.values.targetDepth,
            unitSystem,
          )
          const process = column.key.process
          const overbreak = getOverbreak(process, dailyReport, theoryVolume)
          return {
            id: column.key.id,
            name: column.key.name,
            isDone: columnsDone.includes(column.key.id),
            date: dailyReport?.endDate?.split('T')[0],
            time: dailyReport?.endDate,
            process,
            localX: column.values.localX,
            localY: column.values.localY,
            polygon: column.values.polygon,
            parentId: column.values?.parentId,
            hfType: column.key.type,
            volume: dailyReport?.volume,
            volumeInst: theoryVolume?.toFixed(3),
            startLiftDate: dailyReport?.startLiftDate,
            stopLiftDate: dailyReport?.stopLiftDate,
            startLiftTime: dailyReport?.startLiftDate,
            stopLiftTime: dailyReport?.stopLiftDate,
            startDrillDate: dailyReport?.startDrillDate,
            stopDrillDate: dailyReport?.stopDrillDate,
            startDrillTime: dailyReport?.startDrillDate,
            stopDrillTime: dailyReport?.stopDrillDate,
            drillHrs: getDuration(
              dailyReport,
              columnProd => columnProd.drillingDuration,
            ),
            drillHrsDec: getDurationDec(
              dailyReport,
              columnProd => columnProd.drillingDuration,
              language,
            ),
            liftHrs: getDuration(
              dailyReport,
              columnProd => columnProd.liftingDuration,
            ),
            liftHrsDec: getDurationDec(
              dailyReport,
              columnProd => columnProd.liftingDuration,
              language,
            ),
            plateformLevel: column.values.platformLevel,
            depthInst: column.values.targetDepth,
            depthProd: dailyReport?.depth,
            diameterInst: column.values.diameter,
            diameterProd: dailyReport?.diameter,
            type: column.values.type,
            subtype: column.values.subtype,
            cage: column.values.cage,
            nbr: dailyReport?.nbr,
            rig: dailyReport?.rig,
            zone: column.values.zone,
            timeSpentKS:
              prodEventSummaries?.summariesByTechniqueByColumn?.KS?.[
                column.key.id
              ]?.secondsSpent,
            depthDugKs:
              prodEventSummaries?.summariesByTechniqueByColumn?.KS?.[
                column.key.id
              ]?.depthDug,
            timeSpentBenne:
              prodEventSummaries?.summariesByTechniqueByColumn?.BENNE?.[
                column.key.id
              ]?.secondsSpent,
            depthDugBenne:
              prodEventSummaries?.summariesByTechniqueByColumn?.BENNE?.[
                column.key.id
              ]?.depthDug,
            links: column.links,
            overbreak,
            cageProd: cageProdRecords[column.key.id],
            concreteProd: concreteProdRecords[column.key.id],
            discarded: column.values.discarded,
            pileVolume: dailyReport?.pileVolume,
            asBuiltTheoVolume: dailyReport?.asBuiltTheoVolume,
            prodEvents:
              prodEventSummaries?.summariesByTechniqueByColumn?.BENNE?.[
                column.key.id
              ] != null ||
              prodEventSummaries?.summariesByTechniqueByColumn?.KS?.[
                column.key.id
              ] != null,
          }
        })
        .filter(col => {
          if (
            selectedDateRange?.endDate == null ||
            selectedDateRange?.startDate == null
          ) {
            return true
          }
          return col.date == null
            ? false
            : moment(col.date).isBetween(
                selectedDateRange.startDate.startOf('day'),
                selectedDateRange.endDate.endOf('day'),
                null,
                '[]',
              )
        })
        .filter(col => {
          switch (selectedStatus) {
            case 'COMPLETED':
              return col.isDone
            case 'TODO':
              return !col.isDone
            case 'WARNING':
              return (
                getColumnMissingData(col).warningData.length > 0 ||
                getColumnChecks(col, checksSettings).warningData.length > 0
              )
            case undefined:
              return true
          }
          exhaustiveCheck(selectedStatus)
        })
    } else {
      return []
    }
  },
)

export const getColumnsChecksMissingData = createSelector(
  getJobsiteDataTableValues,
  (columns): ColumnChecksModel[] =>
    columns
      .map(col => getColumnMissingData(col))
      .filter(result => result.warningData.length > 0),
)

export const getColumnsChecksInWarning = createSelector(
  getJobsiteDataTableValues,
  getChecksSettings.getValue,
  (columns, checksSettings): ColumnChecksModel[] => {
    return columns
      .filter(col => getColumnMissingData(col).warningData.length === 0)
      .map(col => getColumnChecks(col, checksSettings))
      .filter(result => result.warningData.length > 0)
  },
)

function getColumnMissingData(
  columnValues: JobsiteTableDataValues,
): ColumnChecksModel {
  const missingData = new ColumnChecksModel(columnValues.id)
  if (columnValues.depthInst == null)
    missingData.warningData.push(new WarningData('depthInst'))
  if (columnValues.depthProd == null)
    missingData.warningData.push(new WarningData('depthProd'))
  if (columnValues.diameterInst == null)
    missingData.warningData.push(new WarningData('diameterInst'))
  if (columnValues.diameterProd == null)
    missingData.warningData.push(new WarningData('diameterProd'))
  if (columnValues.volume == null)
    missingData.warningData.push(new WarningData('volume'))

  return missingData
}

function getColumnChecks(
  columnValues: JobsiteTableDataValues,
  checksSettings: JobsiteChecksSettingsDto,
): ColumnChecksModel {
  const columnWarning = new ColumnChecksModel(columnValues.id)
  if (
    checksSettings.depthCheck.checkEnabled &&
    columnValues.depthProd <= checksSettings.depthCheck.checkValue
  ) {
    columnWarning.warningData.push(
      new WarningData('DEPTH_CHECK', checksSettings.depthCheck.checkValue),
    )
  }
  if (
    checksSettings.depthComparisonCheck.checkEnabled &&
    (columnValues.depthProd >
      columnValues.depthInst *
        (1 + checksSettings.depthComparisonCheck.checkValue / 100) ||
      columnValues.depthProd <
        columnValues.depthInst *
          (1 - checksSettings.depthComparisonCheck.checkValue / 100))
  ) {
    columnWarning.warningData.push(
      new WarningData(
        'DEPTH_COMPARISON_CHECK',
        checksSettings.depthComparisonCheck.checkValue,
      ),
    )
  }
  if (
    checksSettings.diameterCheck.checkEnabled &&
    columnValues.diameterProd !== columnValues.diameterInst
  ) {
    columnWarning.warningData.push(new WarningData('DIAMETER_CHECK'))
  }
  if (
    checksSettings.overBreakCheck.checkEnabled &&
    columnValues.overbreak >= checksSettings.overBreakCheck.checkValue
  ) {
    columnWarning.warningData.push(
      new WarningData(
        'OVERBREAK_CHECK',
        checksSettings.overBreakCheck.checkValue,
      ),
    )
  }

  return columnWarning
}

export const getCSVExportFilteredColumns = createSelector<
  State,
  [TechniqueNames],
  Array<keyof JobsiteTableDataValues>
>(getTechnique, techniqueName => {
  return techniqueName === 'HF'
    ? ['id', 'localX', 'localY', 'nbr', 'diameterProd', 'diameterInst']
    : techniqueName === 'PILES'
    ? ['id', 'polygon', 'hfType', 'parentId', 'prodEvents']
    : ['id', 'prodEvents']
})

export const getFilteredColumns = createSelector<
  State,
  [Array<keyof JobsiteTableDataValues>],
  Array<keyof JobsiteTableDataValues>
>(getCSVExportFilteredColumns, filteredColumns => {
  return filteredColumns.concat('name')
})
