import { TechniqueNames } from '../../../../shared/remote-services/dtos/technique.dto'
import { reducerFromActionHandlers } from '../../../../shared/utils/redux/utils.reducers'
import { CompoundParamSettingsActions } from './compound-param-settings.actions'
import produce from 'immer'

export interface State {
  selectedSettingsTechnique: TechniqueNames | null
}

const initialState: State = {
  selectedSettingsTechnique: null,
}

export const reducers = reducerFromActionHandlers(initialState, [
  {
    actionType:
      CompoundParamSettingsActions.setSelectedSettingsTechnique.CREATE,
    handler: (state, action) => {
      return produce(state, draft => {
        draft.selectedSettingsTechnique = action.payload
      })
    },
  },
])
